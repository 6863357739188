<template>
  <div :style="getElementStyle">
    <el-row v-if="isActive || isDataTableField">
      <el-col :span="3">
        <span class="setting-icon" @click="openSettings">
          <i class="el-icon-s-tools" />
        </span>
      </el-col>
    </el-row>

    <div>
      <!-- <span style="display: none"  >{{ isDefalutPosq}}</span> -->
      <el-row align="middle">
        <el-col
          :span="showLabel && !isDefalutPosq ? 8 : data.description ? 20 : 24"
        >
          <span style="display: flex">
            <icons
              v-if="isIconRequired"
              :iconName="data.icon_name"
              class="mr-1"
            ></icons>
            {{ data.label
            }}<span
              v-if="data.validations.required"
              style="color: red; font-weight: bold"
            >
              *
            </span>
            <i
              class="el-icon-copy-document"
              v-if="!isDataTableField"
              @click="copyDocument"
            ></i>
          </span>
        </el-col>
        <el-col :span="showLabel && isDefalutPosq ? 4 : 0">
          <span>
            <el-popover
              v-if="data.description"
              placement="top-start"
              trigger="hover"
              :content="data.description"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </el-col>
      </el-row>
    </div>
    <div class="icon-block" :style="getStyle">
      <div class="icon">
        <span v-if="data.image_url && !data.validations.multiple">
          <img
            :height="data.height - 30"
            :width="data.width"
            :src="data.image_url"
            alt="icon"
          />
        </span>
        <span
          v-else-if="
            data.folder &&
            (data.folder.thumbnail || data.folder.length) &&
            data.validations.multiple
          "
        >
         <img
                  :src="
                    data.folder.thumbnail ||
                    (data.folder[0] && data.folder[0].thumbnail)
                  "
                  alt="folder thumbnail"
                  style="width: 100%; height: 90%"
                  :class="{ 'focus-border-input': highlight }"
                />
        </span>
        <span v-else>
          <img
            alt="Avatar"
            src="../../../../src/assets/img/default_img.jpg"
            :height="data.height - 30"
            :width="data.width"
            class="inline-block"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchGlobalVariableById } from "@/repo/globalVariables";
export default {
  name: "ImageView",
  components: {},
  props: ["data", "value", "isActive", "isDataTableField","isIconRequired"],
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    getStyle() {
      return `height:${this.data.height - 30}px`;
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    isDefalutPosq() {
      if (this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    if (this.data.validations.multiple && this.data.image_urls) {
      this.folder.images = this.data.image_urls.map((url) => ({
        thumbnail: url,
      }));
    }
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if(this.data.styles.labelStyle == 'right'){
      //   this.isDefalutPos = false;
      // }
    }
  },
  methods: {
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },

    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      // await this.$store.dispatch(
      //   "globalVariables/fetchGlobalVariableById",
      //   params
      // );
      let globalVariable = await fetchGlobalVariableById(params);
      if (globalVariable) {
        if (globalVariable.input_type === "IMAGE") {
          this.getLogoUrlGlobal = globalVariable.image_url;
          this.isList = true;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    },
  },
  watch: {
    isActive: {
      immediate: true,
      handler() {},
    },
  },
  data() {
    return {
      validations: [],
      isDefalutPos: true,
      showLabel: true,
      isList: false,
      highlight: false,
      folder: {
        images: [],
      },
      showGallery: false,
      currentImageIndex: 0,
      thumbnail: "",
    };
  },
};
</script>

<style lang="scss">
.info-right {
  margin-left: 55%;
}
</style>
